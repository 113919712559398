<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>数据统计</template>
      <template v-slot:secondMenu>实时数据</template>
    </breadcrumb-nav>
    <el-card class="filter-container" shadow="never">
      <div class="address-layout">
        <el-row :gutter="24">
          <el-col :span="7">
            <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
              <el-form-item label="" prop="site_id">
                <el-select v-model="listQuery.site_id" @change="selectTrigger(listQuery.site_id)" placeholder="站点选择"
                >
                  <el-option
                    v-for="item in siteOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-button type="primary" icon="el-icon-refresh" @click="onRefresh(listQuery.site_id)"></el-button>
            </el-form>
            <div class="out-border">
              <div class="layout-title">拉起量</div>
              <div class="color-main address-content">
                {{ dataSet.wake }}
              </div>
            </div>
            <div class="out-border">
              <div class="layout-title">CNZZ量</div>
              <div class="color-main address-content">
                {{ dataSet.cnzz }}
              </div>
            </div>
            <div class="out-border">
              <div class="layout-title">拉起率</div>
              <div class="color-main address-content">
                {{ dataSet.rate }}
              </div>
            </div>
            <div class="out-border">
              <div class="layout-title">成本</div>
              <div class="color-main address-content">
                {{ dataSet.cost }}
              </div>
            </div>
          </el-col>
          <el-col :span="17">
            <div class="statistics-layout">
              <div class="layout-title">实时占比</div>
              <div id="charts-main" style="width: 750px;height: 400px;"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { getCnzzSiteList, getCurrentBand } from '../../network/statistics'
import * as echarts from 'echarts'

export default {
  name: 'Real',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      // 查询参数
      listQuery: {
        site_id: ''
      },
      // 站点列表
      siteOptions: [],
      chatMain: '',
      dataSet: {
        wake: 0,
        cnzz: 0,
        rate: 0,
        cost: '0'
      }
    }
  },
  mounted () {
    this.getSiteList()
    this.chartsMain = echarts.init(document.getElementById('charts-main'))
  },
  methods: {
    onRefresh (id) {
      console.log('onrefresh site_id:', id)
      this.listQuery.site_id = id
      this.getDataList()
    },
    selectTrigger (id) {
      console.log('select evt:', id)
      this.listQuery.site_id = id
      this.getDataList()
    },
    getSiteList () {
      getCnzzSiteList({
        page: 1,
        size: 100
      }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('cnzz站点列表获取失败', 'error')
        }
        for (let i = 0; i < result.data.data.length; i++) {
          this.siteOptions.push({
            label: result.data.data[i].site_id,
            value: result.data.data[i].site_id
          })
        }
        if (this.siteOptions.length > 0) {
          this.listQuery.site_id = this.siteOptions[0].value
        }
        console.log('site options:', this.siteOptions)
        this.getDataList()
      })
    },
    getDataList () {
      getCurrentBand({ site_id: this.listQuery.site_id }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('实时数据获取失败', 'error')
        }
        console.log('real data:', result.data)
        this.showLeft(result.data)
        this.showPie(result.data.data)
      })
    },
    showLeft (data) {
      this.dataSet.rate = data.rate.toFixed(2) + '%'
      this.dataSet.wake = data.wake
      this.dataSet.cnzz = data.cnzz
      this.dataSet.cost = data.cost.toFixed(2)
    },
    showPie (srvData) {
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        /* legend: { // 图例
          orient: 'vertical',
          x: 'top',
          data: ['视频广告', '联盟广告', '邮件营销', '直接访问', '搜索引擎']
        }, */
        series: [
          {
            name: '型号占比',
            type: 'pie', // 设置图表类型为饼图
            radius: '55%', // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
            label: {
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)'
              }
            },
            data: []
          }
        ]
      }
      for (let i = 0; i < srvData.length; i++) {
        option.series[0].data.push({
          name: srvData[i].brand,
          value: srvData[i].count
        })
      }
      console.log('pie option:', option)
      this.chartsMain.setOption(option)
    }
  }
}
</script>

<style scoped>
.app-container {
  margin-top: 40px;
  margin-left: 120px;
  margin-right: 120px;
}

.address-layout {
}

.total-layout {
  margin-top: 20px;
}

.total-frame {
  border: 1px solid #DCDFE6;
  padding: 20px;
  height: 100px;
}

.total-icon {
  color: #409EFF;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -50px;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
  left: 70px;
  top: -40px;
}

.un-handle-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}

.layout-title {
  color: #606266;
  padding: 10px 10px;
  background: #F2F6FC;
  font-weight: bold;
}

.un-handle-content {
  padding: 20px 40px;
}

.un-handle-item {
  border-bottom: 1px solid #EBEEF5;
  padding: 10px;
}

.overview-layout {
  margin-top: 20px;
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #DCDFE6;
  margin-bottom: 9px;
}

.statistics-layout {
  border: 1px solid #DCDFE6;
}

.mine-layout {
  position: absolute;
  right: 140px;
  top: 107px;
  width: 250px;
  height: 235px;
}

.address-content {
  padding: 10px;
  font-size: 18px
}
</style>
