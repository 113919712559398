import { request } from './request'

// 获取站点列表
export function getCnzzSiteList (params) {
  return request({
    url: '/statistics/cnzz/site/list',
    method: 'get',
    params
  })
}

// 获取cnzz统计数据列表
export function getCnzzDataList (params) {
  return request({
    url: '/statistics/cnzz/calcdata/list',
    method: 'get',
    params
  })
}

// 获取花费数据列表
export function getCostDataList (params) {
  return request({
    url: '/statistics/cnzz/calcsum/list',
    method: 'get',
    params
  })
}

// 添加新站点
export function addCnzzSite (siteInfo) {
  return request({
    url: '/statistics/cnzz/site/add',
    method: 'post',
    data: { ...siteInfo }
  })
}

// 获取指定id的站点记录
export function getCnzzSite (id) {
  return request({
    url: `/statistics/cnzz/site/${id}`,
    method: 'get'
  })
}

// 修改站点
export function updateCnzzSite (siteInfo) {
  return request({
    url: `/statistics/cnzz/site/${siteInfo.id}`,
    method: 'put',
    data: {
      manager: siteInfo.manager,
      settle: siteInfo.settle,
      unit_price: siteInfo.unit_price,
      comment: siteInfo.comment
    }
  })
}

// 删除站点
export function deleteCnzzSite (id) {
  return request({
    url: `/statistics/cnzz/site/${id}`,
    method: 'delete'
  })
}

export function getCurrentBand (params) {
  return request({
    url: '/statistics/site/brand',
    method: 'get',
    params
  })
}

export function getStatUserTotal (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/stat/total',
    method: 'get',
    params
  })
}

export function getStatUserToday (params) {
  return request({
    baseUrl: window.g.STATISTIC_URL,
    url: '/user/stat/today',
    method: 'get',
    params
  })
}

export function listStatIncUserHistory (params) {
  return request({
    baseUrl: window.g.STATISTIC_URL,
    url: '/user/stat/inc/history',
    method: 'get',
    params
  })
}

export function listStatActiveUserHistory (params) {
  return request({
    baseUrl: window.g.STATISTIC_URL,
    url: '/user/stat/active/history',
    method: 'get',
    params
  })
}

export function getStatUserShareEnter (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/stat/share/enter',
    method: 'get',
    params
  })
}

export function getStatUserShareInvite (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/stat/share/invite',
    method: 'get',
    params
  })
}

export function getStatUserShareEnterTotal (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/stat/share/enter/total',
    method: 'get',
    params
  })
}

export function getStatUserShareInviteTotal (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/stat/share/invite/total',
    method: 'get',
    params
  })
}
